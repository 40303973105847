import React, { useContext } from 'react';
import cn from 'classnames';

import QueryParamsContext from '../../QueryParamsContext';

import styles from './ProfileSummarySection.scss';

export const formatMoney = (amount) => {
  if (!amount) return 'no information';

  if (amount >= 100_000_000) {
    return `${(Math.round(amount / 10_000_000) / 10).toFixed(1)}M`;
  }
  if (amount >= 100_000) {
    return `${Math.round(amount / 100_000)}K`;
  }

  return amount;
};

export const ProfileSummarySection = ({ candidateData }) => {
  const { screenshot } = useContext(QueryParamsContext);

  const {
    candidateRequirement: {
      salaryCentsMin,
      salaryCents,
      oteCentsMin,
      oteCents,
      turboProfileNotes,
    } = {},
  } = candidateData || {};

  const showSalaryOte = salaryCentsMin || salaryCents || oteCentsMin || oteCents;

  const salaryMin = salaryCentsMin ? `$${formatMoney(salaryCentsMin)}` : '';
  const salary = salaryCents ? `$${formatMoney(salaryCents)}` : '';
  const salaryData =
    salaryMin && salary
      ? `${salaryMin}-${salary}`
      : salaryMin
      ? `${salaryMin}`
      : salary
      ? `${salary}`
      : '';

  const oteMin = oteCentsMin ? `$${formatMoney(oteCentsMin)}` : '';
  const ote = oteCents ? `$${formatMoney(oteCents)}` : '';
  const oteData = oteMin && ote ? `${oteMin}-${ote}` : oteMin ? `${oteMin}` : ote ? `${ote}` : '';

  const reasonForLooking = turboProfileNotes ? turboProfileNotes.split('\n') : [];

  return (
    <div className={styles.profileSummarySectionContainer}>
      {showSalaryOte && (
        <div>
          <h2>Compensation target</h2>
          {salaryData && (
            <div className={styles.moneyRangeData}>
              <div className={styles.moneyRangeTitle}>Base: </div>
              <div>{salaryData}</div>
            </div>
          )}
          {oteData && (
            <div className={styles.moneyRangeData}>
              <div className={styles.moneyRangeTitle}>OTE: </div>
              <div>{oteData}</div>
            </div>
          )}
        </div>
      )}
      <div className={cn(styles.recruiterInsights, screenshot && styles.screenshotView)}>
        {reasonForLooking.length > 0 && (
          <>
            <h2>Recruiter Insights</h2>
            <>
              {reasonForLooking.map((line, idx) => (
                <div key={idx}>{line}</div>
              ))}
            </>
          </>
        )}
      </div>
    </div>
  );
};
