import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { Helmet } from 'react-helmet-async';
import queryString from 'query-string';
import cn from 'classnames';

import ConnectContainer from 'containers/ConnectContainer';
import { EmployeeAuthHOC } from 'containers/HOC';

import duck from 'pages/EmployeePortal/CandidateSearchPage/redux';

import { handleError } from 'utils/common';

import {
  promiseGetFullCandidate,
  promiseGetWorkHistories,
} from '../CandidateProfilePages/promises';

import QueryParamsContext from './QueryParamsContext';

import { ProfessionalSummary } from './components/ProfessionalSummary';
import { AvatarSection } from './components/AvatarSection/AvatarSection';
import { ProfileSummarySection } from './components/ProfileSummarySection/ProfileSummarySection';

import styles from './CandidateTurboProfile.scss';
import { EducationSection } from './components/EducationSection/EducationSection';
import { ExecutiveSummary } from './components/ExecutiveSummary';

const CandidateTurboProfile = () => {
  const { id } = useParams();
  const [workHistoriesById, setWorkHistoriesById] = useState({});
  const [currentPositionTitle, setCurrentPositionTitle] = useState('');
  const [currentCompanyName, setCurrentCompanyName] = useState('');
  const [companiesById, setCompaniesById] = useState({});
  const [candidateData, setCandidateData] = useState({});

  const { search } = useLocation();
  const searchData = queryString.parse(search);

  const screenshot = searchData.screenshot === 'true';
  const rm_logo = searchData.rm_logo === 'true';

  const { fullName } = candidateData;

  const pageTitle = `${fullName || 'Candidate'} - ${currentPositionTitle || ''}`;

  const professionalSummaryData = useMemo(() => {
    const cIds = Object.keys(companiesById);

    return cIds.reduce((acc, cId) => {
      const { attributes: { name: companyName, picture: companyPics } = {} } =
        companiesById[cId] || {};

      if (!companyName) return acc;

      const relatedWHs = Object.values(workHistoriesById).filter((wh) => {
        const { relationships: { company: { data: companyIds = [] } = {} } = {} } = wh;

        const companyId = companyIds[0];

        return companyId === cId;
      });

      const workHistoryData = relatedWHs.map(
        ({ attributes: { startDate, endDate, position } }) => ({
          startDate,
          endDate,
          position,
        })
      );

      acc.push({
        companyName,
        companyPics,
        workHistoryData,
      });

      return acc;
    }, []);
  }, [companiesById, workHistoriesById]);

  useEffect(() => {
    promiseGetWorkHistories({
      candidateId: id,
      normalizeIt: true,
      fullEntity: true,
      sortBy: 'end_date_desc_nulls_first,start_date_desc,created_at_desc',
    })
      .then((resp) => {
        const {
          workHistory: { allIds = [], byId: whById = {} } = {},
          company: { byId: cById = {} } = {},
        } = resp;

        const currentWhId = allIds[0];
        if (currentWhId) {
          const currentWh = whById[currentWhId] || {};
          const { attributes: { position } = {} } = currentWh;
          setCurrentPositionTitle(position);
        }

        const currentCompanyId = whById[currentWhId]?.relationships?.company?.data?.[0];
        if (currentCompanyId) {
          const currentCompany = cById[currentCompanyId];
          const {
            attributes: { name },
          } = currentCompany;
          setCurrentCompanyName(name);
        }

        setWorkHistoriesById(whById);
        setCompaniesById(cById);
      })
      .catch(handleError);
  }, [id]);

  useEffect(() => {
    promiseGetFullCandidate({ candidateId: id, normalizeIt: true })
      .then(setCandidateData)
      .catch(handleError);
  }, [id]);

  return (
    <>
      <Helmet key="helmet" title={pageTitle} />
      <QueryParamsContext.Provider value={{ screenshot, rm_logo }}>
        <div className={styles.turboProfileContainer}>
          <div className={cn(styles.profileColumn, screenshot && styles.screenshotView)}>
            <div className={styles.avatarSectionWrapper}>
              <AvatarSection
                candidateData={candidateData}
                currentCompanyName={currentCompanyName}
                currentPositionTitle={currentPositionTitle}
              />
            </div>
            <div className={styles.profileSectionWrapper}>
              <ProfileSummarySection candidateData={candidateData} />
            </div>
          </div>
          <div className={styles.dataColumnGroup}>
            <div className={styles.executiveSummaryColumn}>
              <ExecutiveSummary candidateData={candidateData} />
            </div>
            <div className={styles.professionalSummaryColumn}>
              <ProfessionalSummary data={professionalSummaryData} />
              <EducationSection data={candidateData} />
              <div className={cn(styles.hirewellLogo, screenshot && styles.screenshotView)}>
                {rm_logo ? (
                  <img src="/svgs/rm-long-logo.svg" />
                ) : (
                  <img src="/images/hirewell.png" />
                )}
              </div>
            </div>
          </div>
        </div>
      </QueryParamsContext.Provider>
    </>
  );
};

export default ConnectContainer(duck)(EmployeeAuthHOC()(CandidateTurboProfile));
