import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';

import { handleError } from 'utils/common';
import { trackEvent } from 'utils/analytics';
import {
  segmentSetHiringStatusCandidate,
  segmentSetDesiredCompRoleCandidate,
  segmentSetRoleQuestionsCandidate,
} from 'segment/eventNames';

import { promisePickerOptions } from 'connectors/Defaults/promises';

import { toggleArray } from 'utils/formUtils';

import ConnectContainer from 'containers/ConnectContainer';
import { CandidateAuthHOC } from 'containers/HOC';

import ReadyContainer from 'connectors/ReadyContainer';

import Button from 'components/Button';
import Content from 'components/Content';
import Title from 'components/Title';
import FontIcon from 'components/FontIcon';

import { candidateInputChange } from 'utils/formHelpers/CandidateHelpers';

import { SALES_EXPERIENCE_QUESTIONS } from '../constants';

import duck from '../redux';

import styles from '../Onboarding.scss';

import ProgressNavSection from '../components/ProgressNavSection';

import PrimaryQuestions from './components/PrimaryQuestions';
import SecondaryQuestions from './components/SecondaryQuestions';
import TertiaryQuestions from './components/TertiaryQuestions';

const EVENTS = {
  hiringStatus: segmentSetHiringStatusCandidate,
  ote_cents: segmentSetDesiredCompRoleCandidate,
  salesExperience: segmentSetRoleQuestionsCandidate,
};

@CandidateAuthHOC()
class SetRequirements extends Component {
  constructor(props) {
    super(props);

    const {
      user: {
        currentProfile: {
          candidateRequirement = null,
          hiringStatus,
          managementExperience = null,
          onboardingStatus = '',
          outsideSalesExperience = null,
          recentGrad = null,
          salesExperienceMonths: salesExperience = '',
          closingExperienceMonths: closingExperience = '',
          soldCLevel = null,
          soldSaas = null,
          startupExperience = null,
          workHistories = [],
        } = {},
      } = {},
      recentRole,
    } = props;

    const latestWorkHistory = workHistories[0] || {};

    const { role: recentWHRole } = latestWorkHistory;

    const setHiringStatus =
      onboardingStatus === 'created_profile' ||
      onboardingStatus === 'blocked_companies' ||
      onboardingStatus === 'added_sales_info' ||
      onboardingStatus === 'added_video' ||
      onboardingStatus === 'set_requirements' ||
      onboardingStatus === 'skipped_video' ||
      (candidateRequirement && candidateRequirement.salaryCents !== null);

    this.state = {
      hiringStatus: setHiringStatus ? hiringStatus : '',
      managementExperience,
      outsideSalesExperience,
      recentGrad,
      salesExperience,
      closingExperience,
      soldCLevel,
      soldSaas,
      startupExperience,
      recentRole: latestWorkHistory.id ? recentWHRole : recentRole,
    };
  }

  componentDidMount() {
    const {
      actions: { serverErrorSet },
    } = this.props;

    serverErrorSet({ errors: [] });
    promisePickerOptions()
      .then((pickerOptions) =>
        this.setState({
          pickerOptions,
        })
      )
      .catch(handleError);
  }

  trackEvent = ({ name: inputName }) => {
    const event = EVENTS[inputName];
    if (event) {
      const properties = {};

      const eventObject = { event, properties };

      trackEvent(eventObject);
    }
  };

  handleRecentRoleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value === 'recentGrad') {
      this.handleInputChange({
        target: {
          value: true,
          name: 'recentGrad',
        },
      });

      this.setState({
        recentRole: 'recentGrad',
        recentGrad: true,
      });

      setTimeout(() => {
        if (this.scrollToDiv) {
          this.scrollToDiv.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }
      }, 50);
    } else {
      this.handleInputChange({
        target: {
          value: false,
          name: 'recentGrad',
        },
      });

      this.setState({
        recentRole: value,
        recentGrad: null,
      });

      const {
        actions: { recentRoleSet },
      } = this.props;

      recentRoleSet({ recentRole: value });

      setTimeout(() => {
        if (this.scrollToDiv) {
          this.scrollToDiv.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }
      }, 50);
    }
  };

  handleRoleChange = (event) => {
    const {
      target: {
        value: { role: value },
        name,
      },
    } = event;

    const {
      actions: { setRequirementsStarted },
      user: { currentProfile: { candidateRequirement = {} } = {} } = {},
    } = this.props;

    const { [name]: array } = candidateRequirement;

    const newArray = toggleArray({ array, value });

    const requirementsData = {
      ...this.state,
      candidateRequirement: {
        ...candidateRequirement,
        [name]: newArray,
      },
    };

    setRequirementsStarted({ requirementsData, autosave: true });
  };

  handleWorkModelChange = (event) => {
    const {
      target: {
        value: { workModel: value },
        name,
      },
    } = event;

    const {
      actions: { setRequirementsStarted },
      user: { currentProfile: { candidateRequirement = {} } = {} } = {},
    } = this.props;

    const { [name]: array } = candidateRequirement;

    if (!array) return;

    const newArray = toggleArray({ array, value });

    const requirementsData = {
      ...this.state,
      candidateRequirement: {
        ...candidateRequirement,
        [name]: newArray,
      },
    };

    setRequirementsStarted({ requirementsData, autosave: true });
  };

  handleToggleArray = (event) => {
    const {
      target: { name, label: region },
    } = event;

    const {
      actions: { setRequirementsStarted },
      user: { currentProfile: { candidateRequirement = {} } = {} } = {},
    } = this.props;

    if (region) {
      const regValue = region;
      // eslint-disable-next-line no-unused-vars
      const { [name]: arrayToUpdate = [] } = candidateRequirement;

      // Multiselect:
      // const newArray = arrayToUpdate.map((item) => item.region).includes(regValue)
      //   ? arrayToUpdate.filter((r) => r.region !== regValue)
      //   : [...arrayToUpdate, { type: 'region', region: regValue }];

      // Single select:
      const newArray = arrayToUpdate.map((item) => item.region).includes(regValue)
        ? []
        : [{ type: 'region', region: regValue }];

      const requirementsData = {
        ...this.state,
        candidateRequirement: {
          ...candidateRequirement,
          [name]: newArray,
        },
      };

      return setRequirementsStarted({ requirementsData, autosave: true });
    }
  };

  handleCandReqInputChange = (event) => {
    const {
      target: { name, value, checked, type },
    } = event;

    const {
      actions: { setRequirementsStarted },
      user: { currentProfile: { candidateRequirement = {} } = {} } = {},
    } = this.props;

    const newValue = type === 'checkbox' ? checked : value;

    const requirementsData = {
      ...this.state,
      candidateRequirement: {
        ...candidateRequirement,
        [name]: newValue,
      },
    };

    setRequirementsStarted({ requirementsData, autosave: true });
  };

  handleInputChange = (event) => {
    const {
      target: { value, name },
    } = event;

    this.setState({ [name]: value });

    const {
      actions: { setRequirementsStarted },
      user: { currentProfile: { candidateRequirement = {} } = {} } = {},
    } = this.props;

    const requirementsData = {
      ...this.state,
      candidateRequirement,
      [name]: value,
    };

    setRequirementsStarted({ requirementsData, autosave: true });

    if (name === 'hiringStatus') {
      this.trackEvent({ name });
    }
  };

  handleCandidateTextInputChange = (event) => {
    const {
      actions: { candidatePatchStarted },
      candidate,
    } = this.props;

    const patchArgs = candidateInputChange(event, candidate);

    candidatePatchStarted(patchArgs);
  };

  handleExperienceInputChange = (event) => {
    const {
      target: { years, months, name },
    } = event;

    const {
      actions: { setRequirementsStarted },
      user: { currentProfile: { candidateRequirement = {} } = {} } = {},
    } = this.props;

    const realYears = years === '' || years === null ? 0 : parseInt(years, 10);
    const realMonths = months === '' || months === null ? 0 : parseInt(months, 10);

    const expInMonths = realYears * 12 + realMonths;

    this.setState({ [name]: expInMonths });

    const requirementsData = {
      ...this.state,
      candidateRequirement,
      [name]: expInMonths,
    };

    setRequirementsStarted({ requirementsData, autosave: true });
  };

  handleSubmitForm = () => {
    const {
      actions: { setRequirementsStarted },
      user: { currentProfile: { candidateRequirement = {} } = {} } = {},
    } = this.props;

    const requirementsData = {
      ...this.state,
      candidateRequirement,
    };

    setRequirementsStarted({ requirementsData });
  };

  render() {
    const {
      serverErrors = [],
      user: {
        currentProfile: {
          location,
          candidateRequirement: {
            salaryCents: desiredSalary = '',
            oteCents: desiredOTE = '',
            roles = [],
            locations = [],
            workModel = [],
          } = {},
        } = {},
      } = {},
    } = this.props;

    const {
      hiringStatus,
      managementExperience,
      outsideSalesExperience,
      pickerOptions,
      recentGrad,
      salesExperience,
      closingExperience,
      soldCLevel,
      soldSaas,
      startupExperience,
      recentRole,
    } = this.state;

    const { role: roleOptions = [], candReqLocations: locationSource = [] } = pickerOptions || {};

    const serverErrorMessage = serverErrors.length > 0 ? serverErrors[0] : null;

    const serverErrorContent = serverErrorMessage ? (
      <div className={styles.errorMessage}>{serverErrorMessage}</div>
    ) : null;

    const titleProps = { string: 'Set your requirements' };

    const validField = (value) => value !== null && value !== undefined && value !== '';

    const hasHiringStatus = validField(hiringStatus);

    const hasCandReqComp = validField(desiredOTE) && validField(desiredSalary);

    const hasCandReqRoles = roles.length > 0;

    const hasRecentRole = validField(recentRole);

    const hasCandReqLocation = !!location;

    const hasWorkModel = workModel.length > 0;

    const requiredQuestions = hasRecentRole
      ? SALES_EXPERIENCE_QUESTIONS[recentRole] || SALES_EXPERIENCE_QUESTIONS.default
      : SALES_EXPERIENCE_QUESTIONS['Non-Sales'];

    const requiredValues = requiredQuestions.map((q) => q.camelKey);

    const hasBooleans =
      recentGrad ||
      (hasRecentRole &&
        requiredValues.every((value) => {
          const { [value]: boolToCheck } = this.state;
          return boolToCheck !== null;
        }));

    const hasSalesExperience =
      recentGrad || (hasRecentRole && recentRole === 'Non-Sales')
        ? true
        : validField(salesExperience);

    const hasClosingExperience =
      recentGrad ||
      (hasRecentRole && (recentRole === 'Account Management (CSM/AM)' || recentRole === 'Non-Sales')
        ? true
        : validField(closingExperience));

    const isValid =
      hasHiringStatus &&
      hasCandReqComp &&
      hasRecentRole &&
      hasCandReqRoles &&
      hasCandReqLocation &&
      hasBooleans &&
      hasSalesExperience &&
      hasWorkModel &&
      hasClosingExperience;

    const primaryProps = {
      hiringStatus,
      handleInputChange: this.handleInputChange,
    };

    const secondaryProps = {
      trackEvent: this.trackEvent,
      hasHiringStatus,
      locationSource,
      location,
      roleOptions,
      roles,
      desiredSalary,
      desiredOTE,
      recentRole,
      locations,
      workModel,
      handleToggleArray: this.handleToggleArray,
      handleCandReqInputChange: this.handleCandReqInputChange,
      handleCandidateTextInputChange: this.handleCandidateTextInputChange,
      handleRoleChange: this.handleRoleChange,
      handleWorkModelChange: this.handleWorkModelChange,
      handleRecentRoleChange: this.handleRecentRoleChange,
    };

    const showTertiary =
      hasHiringStatus &&
      hasCandReqComp &&
      hasCandReqRoles &&
      hasCandReqLocation &&
      hasRecentRole &&
      recentRole !== 'recentGrad';

    const tertiaryProps = {
      recentRole,
      hasRecentRole,
      handleInputChange: this.handleInputChange,
      handleExperienceInputChange: this.handleExperienceInputChange,
      managementExperience,
      outsideSalesExperience,
      recentGrad,
      salesExperience,
      closingExperience,
      showTertiary,
      soldSaas,
      soldCLevel,
      startupExperience,
      trackEvent: this.trackEvent,
    };

    const scrollToDiv = (
      <div
        ref={(ref) => {
          this.scrollToDiv = ref;
        }}
        className={styles.scrollToDiv}
      />
    );

    return (
      <>
        <Helmet key="helmet" title="Set Requirements" />

        <ReadyContainer key="SetRequirements" className={styles.CandidateRegistration}>
          <div className={styles.prevButtonContainer} />

          <div className={styles.mainContainer}>
            <ProgressNavSection numFilled={1} />

            <Title {...titleProps} />

            {serverErrorContent}

            <Content className={styles.contentContainer}>
              This helps us understand where you are in your job search and what you expect in your
              next sales role.
            </Content>

            <PrimaryQuestions {...primaryProps} />

            <SecondaryQuestions {...secondaryProps} />

            {scrollToDiv}

            <TertiaryQuestions {...tertiaryProps} />
          </div>
          <div className={styles.nextButtonContainer}>
            <Button
              circle={true}
              active={isValid}
              onClick={this.handleSubmitForm}
              className={styles.actionButton}
              disabled={!isValid}
              data-testid="nextButton"
            >
              <FontIcon iconName="caret-right" />
            </Button>
          </div>
        </ReadyContainer>
      </>
    );
  }
}

export default ConnectContainer(duck)(SetRequirements);
