import React, { useCallback, useEffect, useRef, useState } from 'react';

import Button from 'components/Button';
import Input from 'components/Input';
import FontIcon from 'components/FontIcon';
import { RefreshProfileButton } from 'components/RefreshProfileButton';
import { MarginContainer } from 'components/MarginContainer';

import { toCents, toDollars } from 'utils/moneyUtils';
import { handleError } from 'utils/common';

import getCandidatePublicProfile from 'api/apiCandidates/getCandidatePublicProfile';

import styles from './QuickEditor.scss';

export const QuickEditor = ({
  candReq,
  candidate: { id: candidateId } = {},
  handleCandReqInputChange,
}) => {
  const {
    oteCentsMin = '',
    oteCents = '',
    salaryCentsMin = '',
    salaryCents = '',
    turboProfileNotes = '',
  } = candReq || {};

  const [publicLinks, setPublicLinks] = useState([]);

  const [publicProfileId, setPublicProfileId] = useState(null);

  useEffect(() => {
    getCandidatePublicProfile(candidateId)
      .then((resp) => {
        const { data: { publicProfile: { id, publicLinks } = {} } = {} } = resp;

        setPublicLinks(publicLinks || []);
        setPublicProfileId(id);
      })
      .catch(handleError);
  }, [candidateId]);

  const copyThisText = useRef();
  const [linkCopied, setLinkCopied] = useState(undefined);

  const handleCopy = useCallback((textToCopy, title) => {
    copyThisText.current.value = textToCopy;
    copyThisText.current.select();

    document.execCommand('copy');
    copyThisText.current.blur();

    setLinkCopied({ title });

    setTimeout(() => {
      setLinkCopied(undefined);
    }, 500);
  }, []);

  return (
    <div className={styles.quickEditorContainer}>
      {publicLinks.length > 0 ? (
        <div className={styles.linkSection}>
          {publicLinks.map(({ title, url }) => (
            <React.Fragment key={title}>
              <div>{title}</div>
              <div>
                <Button onClick={() => handleCopy(url, title)}>
                  {linkCopied && linkCopied.title === title ? (
                    <>
                      <FontIcon iconName="checkmark-circle" className={styles.copiedLinkIcon} />
                      Copied!
                    </>
                  ) : (
                    <>
                      <FontIcon iconName="share" className={styles.shareIcon} />
                      Share
                    </>
                  )}
                </Button>
              </div>
              <div>
                <Button primary to={url} fullUrl sameTab={false}>
                  Open
                </Button>
              </div>
            </React.Fragment>
          ))}
        </div>
      ) : (
        <div className={styles.linksSectionPlaceholder} />
      )}
      <div className={styles.editorSection}>
        <div className={styles.inputsArea}>
          <MarginContainer top="-16">
            <div className={styles.moneyInputsRow}>
              <Input
                size="full"
                name="salaryCentsMin"
                oteInputOne={true}
                value={salaryCentsMin || salaryCentsMin === 0 ? toDollars(salaryCentsMin) : ''}
                placeholder="Base"
                label="Min Desired Base Salary"
                moneyInput={true}
                className={styles.oteInputContainer}
                handleInputChange={(event) =>
                  handleCandReqInputChange({
                    ...event,
                    target: {
                      ...event.target,
                      value: toCents(event.target.value),
                      name: event.target.name,
                    },
                  })
                }
              />
              <Input
                size="full"
                name="salaryCents"
                oteInputOne={true}
                value={salaryCents || salaryCents === 0 ? toDollars(salaryCents) : ''}
                placeholder="Base"
                label="Desired Base Salary"
                required
                moneyInput={true}
                className={styles.oteInputContainer}
                handleInputChange={(event) =>
                  handleCandReqInputChange({
                    ...event,
                    target: {
                      ...event.target,
                      value: toCents(event.target.value),
                      name: event.target.name,
                    },
                  })
                }
              />
            </div>
          </MarginContainer>
          <MarginContainer top="8px">
            <div className={styles.moneyInputsRow}>
              <Input
                size="full"
                name="oteCentsMin"
                oteInputOne={true}
                value={oteCentsMin || oteCentsMin === 0 ? toDollars(oteCentsMin) : ''}
                placeholder="OTE"
                label="Min Desired OTE"
                moneyInput={true}
                className={styles.oteInputContainer}
                handleInputChange={(event) =>
                  handleCandReqInputChange({
                    ...event,
                    target: {
                      ...event.target,
                      value: toCents(event.target.value),
                      name: event.target.name,
                    },
                  })
                }
              />
              <Input
                size="full"
                name="oteCents"
                oteInputOne={true}
                value={oteCents || oteCents === 0 ? toDollars(oteCents) : ''}
                placeholder="OTE"
                label="Desired OTE"
                required
                moneyInput={true}
                className={styles.oteInputContainer}
                handleInputChange={(event) =>
                  handleCandReqInputChange({
                    ...event,
                    target: {
                      ...event.target,
                      value: toCents(event.target.value),
                      name: event.target.name,
                    },
                  })
                }
              />
            </div>
          </MarginContainer>
          <div className={styles.notesInput}>
            <Input
              type="textarea"
              size="full"
              rows="4"
              handleInputChange={handleCandReqInputChange}
              name="turboProfileNotes"
              value={turboProfileNotes}
              placeholder="Notes"
              required
              label="Recruiter Insights"
            />
          </div>
        </div>
        <div>
          <RefreshProfileButton publicProfileId={publicProfileId} />
        </div>
      </div>
      <input ref={copyThisText} style={{ position: 'absolute', right: '10000px' }} />
    </div>
  );
};
